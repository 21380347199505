(function () {
    let loanglide;
    (loanglide = function () {}).prototype = {};
    window.loanglide = window.loanglide || new loanglide();

    const public_api_key = window.lg_config && window.lg_config.api_key;
    const appVersion = window.lg_config && window.lg_config.version;

    const apiURL = process.env.API_URL;
    // const apiURL = "http://localhost:8050";

    const params = new URLSearchParams(window.location.search);
    const lowercaseParams = new URLSearchParams();
    for (const [name, value] of params) {
        lowercaseParams.append(name.toLowerCase(), value);
    }
    let pc = lowercaseParams.get("pc");
    if (!pc) {
        pc = "FRD";
    }

    const utils = {
        prettifyCurrency: function (amount, ignoreFixedDecimal) {
            if (amount === "N/A" || amount === "-") return amount;
            if (amount === undefined || amount === null) return "$0";
            if (!ignoreFixedDecimal) {
                amount = parseFloat(amount);
                if (isNaN(amount)) return "-";
                amount = Math.round((amount + Number.EPSILON) * 100) / 100;
                amount = amount.toFixed(2);
            }
            return "$" + amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        addStyleRule: (function (style) {
            let sheet = document.head.appendChild(style).sheet;
            return function (selector, css) {
                let propText =
                    typeof css === "string"
                        ? css
                        : Object.keys(css)
                              .map(function (p) {
                                  return p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]);
                              })
                              .join(";");
                sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
            };
        })(document.createElement("style")),
        debounce: function (func, wait, immediate) {
            let timeout;
            return function () {
                let context = this,
                    args = arguments;
                let later = function () {
                    timeout = null;
                    if (!immediate) func.apply(context, args);
                };
                let callNow = immediate && !timeout;
                clearTimeout(timeout);
                timeout = setTimeout(later, wait);
                if (callNow) func.apply(context, args);
            };
        },
    };

    function openLearnMoreModal({ dataVersion }) {
        document.body.style = "overflow:hidden;";
        document.body.appendChild(
            (function (containerDiv) {
                containerDiv.id = "lg-app";
                containerDiv.style.cssText =
                    "-webkit-tap-highlight-color:transparent;overflow:hidden;position:fixed;bottom:0px;left:0px;right:0px;top:0px;z-index:9999999;margin:0px;border-color:0px;padding:0px;display:block;";
                containerDiv.appendChild(
                    (function (iframeBg) {
                        iframeBg.style = "width:100%;height:100%;margin:0px;padding:0px";
                        iframeBg.appendChild(
                            (function (iframe) {
                                iframe.id = "micro-application";

                                // let borrowerAppURL = "http://localhost:3050/widget";
                                let borrowerAppURL = `${process.env.BORROWER_APP_URL}/microapp/learnmore`;
                                if (appVersion && appVersion === "v2") {
                                    borrowerAppURL = `${process.env.NEW_BORROWER_APP_URL}/widget/learn-more`;
                                }
                                if (dataVersion && dataVersion === "v2") {
                                    borrowerAppURL = `${process.env.NEW_BORROWER_APP_URL}/widget/learn-more`;
                                }
                                iframe.src = `${borrowerAppURL}?Business=${window.loanglide.BusinessId}&ServiceProvider=${window.loanglide.ServiceProviderId}`;

                                iframe.style.cssText = "overflow:hidden;margin:0;padding:0;border:none";
                                iframe.width = "100%";
                                iframe.height = "100%";
                                return iframe;
                            })(document.createElement("iframe"))
                        );
                        return iframeBg;
                    })(document.createElement("div"))
                );
                return containerDiv;
            })(document.createElement("div"))
        );
    }
    function renderStartingAtWidget({ el, startingAtAmount, pc, colors, dataContent, dataVersion }) {
        el.appendChild(
            (function (span) {
                span.className = "lg-learn-more-tagline";
                span.style.color = colors.primaryTextColor || "#000000";
                span.innerHTML = 'Starting at <span style="font-weight: 600">' + (startingAtAmount ? `$${startingAtAmount}/mo` : "-") + "</span>";
                return span;
            })(document.createElement("div"))
        );
        el.appendChild(
            (function (img) {
                img.src = `https://cdn.loanglide.com/v2/assets/${pc.toUpperCase()}/logo.png`;
                img.style.cssText = "width:76px;height:26px;max-height:26px;margin:-6px 7px 0 7px;";
                return img;
            })(document.createElement("img"))
        );
        el.appendChild(
            (function (contentEl) {
                contentEl.id = "lg-learn-more-content";
                contentEl.className = "lg-learn-more-content";
                contentEl.onclick = function () {
                    openLearnMoreModal({ dataVersion });
                };
                contentEl.innerHTML = dataContent;
                contentEl.style.cssText = "text-decoration:underline;cursor:pointer";
                contentEl.style.color = colors.primaryDarkColor || "#6f7a87";
                return contentEl;
            })(document.createElement("div"))
        );

        el.childNodes.forEach((ch, idx) => {
            if (idx > 2) {
                el.removeChild(ch);
            }
        });
    }
    function initLearnMoreElement() {
        let colors = {};
        if (window.lg_config && window.lg_config.colors) {
            colors = window.lg_config.colors;
        }

        document.querySelectorAll(".lg-learn-more").forEach(function (el) {
            while (el.lastElementChild) {
                el.removeChild(el.lastElementChild);
            }

            el.style.cssText = "display: flex;";

            const dataAmount = el.getAttribute("data-amount");
            const dataContent = el.getAttribute("data-content") || "Learn More";

            const dataLoanPlan = el.getAttribute("data-loan-plan");

            const businessId = window.loanglide.BusinessId || el.getAttribute("data-business-id");

            const dataVersion = el.getAttribute("data-version");

            if (businessId && dataLoanPlan) {
                const [dataLoanPlanTerm] = dataLoanPlan.match(/\d+/g);

                if (dataLoanPlanTerm) {
                    let getPayments = new XMLHttpRequest();
                    getPayments.onreadystatechange = function () {
                        if (getPayments.readyState == XMLHttpRequest.OPENED) {
                        } else if (getPayments.readyState == XMLHttpRequest.DONE && getPayments.status === 200) {
                            const getPaymentsResponse = JSON.parse(getPayments.response);

                            if (getPaymentsResponse.data.length === 0) {
                                // no-plan-available
                                renderStartingAtWidget({
                                    el,
                                    startingAtAmount: parseFloat(dataAmount / 9).toFixed(2),
                                    pc,
                                    colors,
                                    dataContent,
                                    dataVersion,
                                });
                                return;
                            }

                            // plan-available
                            const response = getPaymentsResponse.data;
                            let userLoanPlan;
                            if (Object.keys(response.LoanPlans).length !== 0) {
                                Object.keys(response.LoanPlans).forEach((key) => {
                                    if (response.LoanPlans[key].LoanPlanCode === dataLoanPlan) {
                                        userLoanPlan = response.LoanPlans[key];
                                    }
                                });
                            }

                            if (userLoanPlan) {
                                renderStartingAtWidget({
                                    el,
                                    startingAtAmount: parseFloat(dataAmount / dataLoanPlanTerm).toFixed(2),
                                    pc,
                                    colors,
                                    dataContent,
                                    dataVersion,
                                });
                            } else {
                                renderStartingAtWidget({
                                    el,
                                    startingAtAmount: parseFloat(dataAmount / 9).toFixed(2),
                                    pc,
                                    colors,
                                    dataContent,
                                    dataVersion,
                                });
                            }
                        } else if (getPayments.readyState == XMLHttpRequest.DONE && getPayments.status !== 200) {
                            // some-error occured case
                            renderStartingAtWidget({
                                el,
                                startingAtAmount: parseFloat(dataAmount / 9).toFixed(2),
                                pc,
                                colors,
                                dataContent,
                                dataVersion,
                            });
                        }
                    };
                    getPayments.open("POST", `${apiURL}/api/v1/payments`, true);
                    getPayments.setRequestHeader("content-type", "application/json");
                    getPayments.send(
                        JSON.stringify({
                            businessId,
                            amount: parseFloat(dataAmount).toFixed(2),
                        })
                    );
                } else {
                    renderStartingAtWidget({ el, startingAtAmount: parseFloat(dataAmount / 9).toFixed(2), pc, colors, dataContent, dataVersion });
                }
            } else {
                renderStartingAtWidget({ el, startingAtAmount: parseFloat(dataAmount / 9).toFixed(2), pc, colors, dataContent, dataVersion });
            }
        });
    }

    function getBusinessDetails(businessId) {
        return new Promise((resolve, reject) => {
            let businessResp = new XMLHttpRequest();
            businessResp.onreadystatechange = function () {
                if (businessResp.readyState == XMLHttpRequest.OPENED) {
                }
                if (businessResp.readyState == XMLHttpRequest.DONE && businessResp.status === 200) {
                    try {
                        let resp = JSON.parse(businessResp.responseText);
                        if (Array.isArray(resp.data) && resp.data.length !== 0) {
                            let bs = resp.data.filter((r) => r.IsBusinessOwner === "Y");
                            if (bs.length !== 0) {
                                resolve(bs[0]);
                            } else {
                                reject();
                            }
                        } else {
                            reject();
                        }
                    } catch (error) {
                        reject();
                    }
                } else if (businessResp.readyState == XMLHttpRequest.DONE && businessResp.status !== 200) {
                    reject();
                }
            };
            businessResp.open("POST", `${apiURL}/api/v1/q/search/serviceproviders`, true);
            businessResp.setRequestHeader("content-type", "application/json");
            businessResp.send(
                JSON.stringify({
                    searchCriteria: { businessID: businessId },
                })
            );
        });
    }

    /** header-element */
    function initHeaderElement() {
        let colors = {};
        if (window.lg_config && window.lg_config.colors) {
            colors = window.lg_config.colors;
        }

        document.querySelectorAll(".lg-header").forEach(async function (containerDiv) {
            while (containerDiv.lastElementChild) {
                containerDiv.removeChild(containerDiv.lastElementChild);
            }

            const buttonType = containerDiv.getAttribute("data-button-type") || "apply-now";
            const theme = containerDiv.getAttribute("data-theme") || "primary";

            const businessId = containerDiv.getAttribute("data-business-id");

            const dataVersion = containerDiv.getAttribute("data-version");

            let businessDetails;
            if (businessId) {
                try {
                    businessDetails = await getBusinessDetails(businessId);
                } catch (error) {
                    containerDiv.appendChild(
                        (function (error) {
                            error.id = "lg-header-error";
                            error.style.cssText = "font-size:0.7rem;border:1px solid rgba(0, 0, 0, 0.04);padding:24px;color:#FF0000";
                            error.innerHTML = "Some error occured in fetching details!";
                            return error;
                        })(document.createElement("div"))
                    );
                    return;
                }
            } else {
                containerDiv.appendChild(
                    (function (error) {
                        error.id = "lg-header-error";
                        error.style.cssText = "font-size:0.7rem;border:1px solid rgba(0, 0, 0, 0.04);padding:24px;color:#FF0000";
                        error.innerHTML = "Error while initialising element, missing business id!";
                        return error;
                    })(document.createElement("div"))
                );
                return;
            }

            containerDiv.style.cssText = "padding:24px;margin:0;border-radius:4px";
            containerDiv.style.background = colors.backgroundColor || "#e4f5da";
            containerDiv.appendChild(
                (function (titleEl) {
                    titleEl.className = "lg-header-title";
                    titleEl.style.cssText = "font-weight:bold;font-size:1.52rem;margin-bottom:7px";
                    titleEl.style.color = colors.primaryTextColor || "#000000";
                    titleEl.innerHTML = "Financing Available";
                    return titleEl;
                })(document.createElement("div"))
            );
            containerDiv.appendChild(
                (function (taglineEl) {
                    taglineEl.className = "lg-header-tagline";
                    taglineEl.style.cssText = "font-weight:normal;font-size:0.92rem;margin-bottom:16px";
                    taglineEl.style.color = colors.secondaryTextColor || "#000000";
                    taglineEl.innerHTML =
                        "We are proud to offer financing powered by Loanglide to help you break your project cost into simple monthly payments.";
                    return taglineEl;
                })(document.createElement("div"))
            );
            containerDiv.appendChild(
                (function (ctaImageEl) {
                    ctaImageEl.src = `https://cdn.loanglide.com/widgets/loanglide/images/${theme}/${buttonType}.png`;
                    ctaImageEl.style.cssText = "width:228px;cursor:pointer;";
                    if (buttonType === "apply-now") ctaImageEl.style.width = "162px";
                    if (buttonType === "pay-over-time") ctaImageEl.style.width = "186px";
                    ctaImageEl.onclick = function () {
                        // let borrowerAppURL = "http://localhost:3050";
                        let borrowerAppURL = `${process.env.BORROWER_APP_URL}`;
                        if (appVersion && appVersion === "v2") {
                            borrowerAppURL = `${process.env.NEW_BORROWER_APP_URL}`;
                        }
                        if (dataVersion && dataVersion === "v2") {
                            borrowerAppURL = `${process.env.NEW_BORROWER_APP_URL}`;
                        }

                        let url = borrowerAppURL;
                        if (businessDetails && businessDetails.LenderTypeCode === "D") {
                            url += "/direct";
                        }
                        if (businessDetails) {
                            url += `/?Business=${businessDetails.BusinessID}&ServiceProvider=${businessDetails.ServiceProviderID}`;
                        }
                        window.open(url, "_blank");
                    };
                    return ctaImageEl;
                })(document.createElement("img"))
            );
        });
    }
    /** header-element */

    /** why-financing-element */
    function initFinancingElement() {
        let colors = {};
        if (window.lg_config && window.lg_config.colors) {
            colors = window.lg_config.colors;
        }

        document.querySelectorAll(".lg-financing").forEach(async function (containerDiv) {
            while (containerDiv.lastElementChild) {
                containerDiv.removeChild(containerDiv.lastElementChild);
            }

            const businessId = containerDiv.getAttribute("data-business-id");

            let businessDetails;
            if (businessId) {
                try {
                    businessDetails = await getBusinessDetails(businessId);
                } catch (error) {
                    containerDiv.appendChild(
                        (function (error) {
                            error.id = "lg-header-error";
                            error.style.cssText = "font-size:0.7rem;border:1px solid rgba(0, 0, 0, 0.04);padding:24px;color:#FF0000";
                            error.innerHTML = "Error while initialising element, missing business id!";
                            return error;
                        })(document.createElement("div"))
                    );
                    return;
                }
            } else {
                containerDiv.appendChild(
                    (function (error) {
                        error.id = "lg-header-error";
                        error.style.cssText = "font-size:0.7rem;border:1px solid rgba(0, 0, 0, 0.04);padding:24px;color:#FF0000";
                        error.innerHTML = "Error while initialising element, missing business id!";
                        return error;
                    })(document.createElement("div"))
                );
                return;
            }

            containerDiv.style.cssText = "padding:24px;border-radius:4px";
            containerDiv.style.background = colors.backgroundColor || "#e4f5da";
            containerDiv.appendChild(
                (function (titleEl) {
                    titleEl.className = "lg-financing-title";
                    titleEl.style.cssText = "font-weight:bold;font-size:1.52rem;margin-bottom:8px";
                    titleEl.style.color = colors.primaryTextColor || "#000000";
                    titleEl.innerHTML = "Why Loanglide financing?";
                    return titleEl;
                })(document.createElement("div"))
            );
            containerDiv.appendChild(
                (function (ulEl) {
                    ulEl.className = "lg-financing-points";
                    ulEl.style.cssText = "font-size:0.85rem;margin:0;padding-left:20px;";
                    ulEl.style.color = colors.secondaryTextColor || "rgba(0,0,0,0.65)";

                    const points = [
                        "Simple 100% paperless application",
                        "Check your rates for free with no impact to credit score",
                        `${
                            businessDetails.LenderTypeCode === "D"
                                ? "Multiple offers to choose from starting at 3.99% APR"
                                : "Multiple offers to choose from starting at 0% APR"
                        }`,
                        "Complete application and e-sign in 3 minutes",
                        "No pre-payment penalties",
                    ];

                    for (let i in points) {
                        ulEl.appendChild(
                            (function (liEl) {
                                liEl.className = "lg-financing-points-item";
                                liEl.style.cssText = "margin-top:3px";
                                liEl.innerHTML = points[i];
                                return liEl;
                            })(document.createElement("li"))
                        );
                    }
                    return ulEl;
                })(document.createElement("ul"))
            );
        });
    }
    /** why-financing-element */

    /** video-element */
    function initVideoElement() {
        document.querySelectorAll(".lg-video").forEach(function (containerDiv) {
            while (containerDiv.lastElementChild) {
                containerDiv.removeChild(containerDiv.lastElementChild);
            }

            const videoType = containerDiv.getAttribute("data-video-type");
            const posterUrl =
                videoType === "indirect"
                    ? "https://cdn.loanglide.com/dev/images/borrower-indirect-financing-poster.png"
                    : videoType === "direct"
                    ? "https://cdn.loanglide.com/dev/images/borrower-direct-financing-poster.png"
                    : "";
            const videoUrl =
                videoType === "indirect"
                    ? "https://cdn.loanglide.com/dev/videos/borrower-indirect-financing.mp4"
                    : videoType === "direct"
                    ? "https://cdn.loanglide.com/dev/videos/borrower-direct-financing.mp4"
                    : "";
            containerDiv.appendChild(
                (function (videoEl) {
                    videoEl.style.cssText = `width:100%;height:100%;object-fit:fill;max-width:520px;border-radius:4px`;
                    videoEl.controls = true;
                    videoEl.poster = posterUrl || "https://cdn.loanglide.com/dev/images/borrower-indirect-financing-poster.png";
                    videoEl.appendChild(
                        (function (videoSource) {
                            videoSource.src = videoUrl || "https://cdn.loanglide.com/dev/videos/borrower-indirect-financing.mp4";
                            return videoSource;
                        })(document.createElement("source"))
                    );
                    return videoEl;
                })(document.createElement("video"))
            );
        });
    }
    /** video-element */

    /** faqs-element */
    function initFaqsElement() {
        let colors = {};
        if (window.lg_config && window.lg_config.colors) {
            colors = window.lg_config.colors;
        }

        document.querySelectorAll(".lg-faqs").forEach(function (containerDiv) {
            while (containerDiv.lastElementChild) {
                containerDiv.removeChild(containerDiv.lastElementChild);
            }

            containerDiv.style.cssText = "padding:24px;margin:0";
            containerDiv.style.background = colors.backgroundColor || "#FFFFFF";
            containerDiv.appendChild(
                (function (faqTitle) {
                    faqTitle.className = "lg-faqs-title";
                    faqTitle.innerHTML = "FAQs";
                    faqTitle.style.cssText = "font-weight:bold;font-size:1.52rem;margin-bottom:16px";
                    faqTitle.style.color = colors.primaryTextColor || "#000000";
                    return faqTitle;
                })(document.createElement("div"))
            );
            const faqs = [
                { q: "1. How much does it cost to use Loanglide?", a: "Nothing, Loanglide is free for consumers." },
                {
                    q: "2. Will my credit score be affected when I check my interest rate?",
                    a: "No. Checking you interest rate does not affect your credit score.",
                },
                {
                    q: "3. What document will I need to provide?",
                    a: "None. We do not ask for any document for most of our loan applications. In certain  cases, the loan may need to be reviewed based on our credit bureau report and we may need relevant documents to process the application.",
                },
                {
                    q: "4. When will I find out if I have pre-qualified?",
                    a: "Immediately ofter completing the application. For most of the customer, it takes less than 2 minute to pre-qualify",
                },
            ];
            faqs.forEach(function (data) {
                containerDiv.appendChild(
                    (function (faqQuestion) {
                        faqQuestion.innerHTML = data.q;
                        faqQuestion.style.cssText = "font-weight:bold;font-size:0.88rem";
                        faqQuestion.style.color = colors.primaryTextColor || "#000000";
                        faqQuestion.appendChild(
                            (function (faqAnswer) {
                                faqAnswer.style.cssText = "font-weight:normal;font-size:0.78rem;margin-top:4px;margin-bottom:20px";
                                faqAnswer.style.color = colors.secondaryTextColor || "rgba(0,0,0,0.65)";
                                faqAnswer.innerHTML = data.a;
                                return faqAnswer;
                            })(document.createElement("div"))
                        );
                        return faqQuestion;
                    })(document.createElement("div"))
                );
            });
            containerDiv.appendChild(
                (function (moreQuestionsText) {
                    moreQuestionsText.style.cssText = "font-size:0.78rem;margin-top:24px";
                    moreQuestionsText.style.color = colors.secondaryTextColor || "rgba(0,0,0,0.65)";
                    moreQuestionsText.innerHTML = "Have more questions? Check out the detailed LoanGlide FAQs ";
                    moreQuestionsText.appendChild(
                        (function (hereLink) {
                            hereLink.className = "lg-faqs-here";
                            hereLink.style.cssText = "cursor:pointer;text-decoration:underline;";
                            hereLink.style.color = colors.primaryDarkColor || "#71c347";
                            hereLink.innerHTML = "Here";
                            hereLink.onclick = function () {
                                window.open("https://www.loanglide.com/faqs", "_blank");
                            };
                            return hereLink;
                        })(document.createElement("span"))
                    );
                    return moreQuestionsText;
                })(document.createElement("div"))
            );
        });
    }
    /** faqs-element */

    /** calculator-element */
    function initPayments() {
        let colors = {};
        if (window.lg_config && window.lg_config.colors) {
            colors = window.lg_config.colors;
        }

        function appendViewMore(mainDiv, data, _viewMore) {
            mainDiv.appendChild(
                (function (viewMore) {
                    viewMore.style.cssText = "text-decoration:underline;cursor:pointer";
                    viewMore.style.color = colors.primaryDarkColor || "#71c347";
                    viewMore.innerHTML = "View More";
                    viewMore.setAttribute("desc-type", "short");
                    viewMore.onclick = function () {
                        if (viewMore.getAttribute("desc-type") === "short") {
                            mainDiv.innerHTML = `${data.LoanPlanCode}: ${data.LongDescription} `;
                            viewMore.innerHTML = "Hide More";
                            viewMore.setAttribute("desc-type", "long");
                        } else {
                            mainDiv.innerHTML = `${data.LoanPlanCode}: ${data.ShortDescription}.. `;
                            viewMore.innerHTML = "View More";
                            viewMore.setAttribute("desc-type", "short");
                        }
                        appendViewMore(mainDiv, data, viewMore);
                    };
                    return _viewMore || viewMore;
                })(document.createElement("span"))
            );
        }
        function addLoanPlanCards(mainDiv, data, paymentType2Available) {
            document.querySelectorAll(".lg-calculator-loan-plan-card").forEach((it) => it.remove());
            Object.keys(data).forEach(function (t) {
                mainDiv.appendChild(
                    (function (planDiv) {
                        planDiv.style.cssText = "border-radius:4px;padding:8px;margin:16px 0";
                        planDiv.style.background = colors.primaryColor || "#e4f5da";
                        planDiv.className = "lg-calculator-loan-plan-card";

                        planDiv.appendChild(
                            (function (firstDiv) {
                                firstDiv.style.cssText = "display:grid;grid-template-columns:repeat(3,1fr);font-size:0.8rem;font-weight:bold";
                                [1, 2, 3].forEach(function (idx) {
                                    firstDiv.appendChild(
                                        (function (col) {
                                            switch (idx) {
                                                case 1:
                                                    col.innerHTML = `${t} months`;
                                                    break;
                                                case 2:
                                                    col.innerHTML = !paymentType2Available
                                                        ? data[t].MonthlyPaymentRange
                                                        : `$${data[t].MinimumMonthlyPayment}`;
                                                    break;
                                                case 3:
                                                    col.innerHTML = !paymentType2Available
                                                        ? data[t].InterestRange
                                                        : `$${data[t].RecommendedMonthlyPayment}`;
                                                    break;
                                            }
                                            return col;
                                        })(document.createElement("div"))
                                    );
                                });
                                return firstDiv;
                            })(document.createElement("div"))
                        );
                        if (paymentType2Available) {
                            planDiv.appendChild(
                                (function (seprator) {
                                    seprator.style.cssText = "border:solid 1px #a8a8a8;height:1px;margin:14px 0;";
                                    return seprator;
                                })(document.createElement("div"))
                            );
                            planDiv.appendChild(
                                (function (description) {
                                    description.style.cssText = "font-size:0.74rem;font-weight:600";
                                    description.innerHTML = `${data[t].LoanPlanCode}: ${data[t].ShortDescription}.. `;
                                    appendViewMore(description, data[t]);
                                    return description;
                                })(document.createElement("div"))
                            );
                        }
                        return planDiv;
                    })(document.createElement("div"))
                );
            });
        }
        function addTableHeader(mainDiv, paymentType2Available) {
            document.querySelectorAll(".lg-loan-plan-card-header").forEach((it) => it.remove());

            let headersArray = paymentType2Available
                ? ["Initial period in Months", "Monthly payment duting</br>initial period", "Recommended monthly payment to avoid interest"]
                : ["Term", "Monthly Payment", "Interest Rate (APR)"];

            mainDiv.appendChild(
                (function (tableHeaders) {
                    tableHeaders.className = "lg-loan-plan-card-header";
                    tableHeaders.style.cssText = "display:grid;grid-template-columns:repeat(3,1fr);font-size:0.8rem;margin-top:20px;font-weight:bold";
                    headersArray.forEach(function (th) {
                        tableHeaders.appendChild(
                            (function (he) {
                                he.innerHTML = th;
                                return he;
                            })(document.createElement("div"))
                        );
                    });
                    return tableHeaders;
                })(document.createElement("div"))
            );
        }
        utils.addStyleRule(".lg-calculator-slider::-webkit-slider-thumb", {
            "-webkit-appearance": "none",
            appearance: "none",
            width: "20px",
            height: "20px",
            background: colors.primaryDarkColor || "#71c347",
            cursor: "pointer",
            "border-radius": "100%",
        });
        try {
            utils.addStyleRule(".lg-calculator-slider::-moz-range-thumb", {
                "-webkit-appearance": "none",
                appearance: "none",
                width: "20px",
                height: "20px",
                background: colors.primaryDarkColor || "#71c347",
                cursor: "pointer",
                "border-color": colors.primaryDarkColor || "#71c347",
                "border-radius": "100%",
            });
        } catch (error) {}

        document.querySelectorAll(".lg-calculator").forEach(function (containerDiv) {
            containerDiv.style.cssText = "padding:24px;margin:0;border-radius:6px";
            containerDiv.style.background = colors.backgroundColor || "#f7f7f7";
            const businessId = containerDiv.getAttribute("data-business-id");
            const serviceProviderId = containerDiv.getAttribute("data-service-provider-id");
            const loanPurpose = containerDiv.getAttribute("data-loan-purpose");

            let getPayments = new XMLHttpRequest();
            getPayments.onreadystatechange = function () {
                if (getPayments.readyState == XMLHttpRequest.OPENED) {
                    document.getElementById("lg-calculator-error") && document.getElementById("lg-calculator-error").remove();
                    containerDiv.appendChild(
                        (function (loading) {
                            loading.id = "lg-calculator-loading";
                            loading.style.cssText = "text-align:center;font-size:0.8rem;font-weight:bold";
                            loading.style.color = colors.primaryTextColor || "#000000";
                            loading.innerHTML = "Loading...";
                            return loading;
                        })(document.createElement("div"))
                    );
                } else if (getPayments.readyState == XMLHttpRequest.DONE && getPayments.status === 200) {
                    containerDiv.innerHTML = "";
                    const getPaymentsResponse = JSON.parse(getPayments.response);

                    document.getElementById("lg-calculator-loading") && document.getElementById("lg-calculator-loading").remove();
                    document.getElementById("lg-calculator-error") && document.getElementById("lg-calculator-error").remove();
                    document.getElementById("lg-calculator-no-plan") && document.getElementById("lg-calculator-no-plan").remove();
                    if (getPaymentsResponse.data.length === 0) {
                        containerDiv.appendChild(
                            (function (error) {
                                error.id = "lg-calculator-no-plan";
                                error.style.cssText = "text-align:center;font-size:0.8rem;color:black";
                                error.innerHTML = "No plans available!";
                                return error;
                            })(document.createElement("div"))
                        );
                        return;
                    }

                    containerDiv.appendChild(
                        (function (calculatorTitle) {
                            calculatorTitle.className = "lg-calculator-title";
                            calculatorTitle.innerHTML = "Want to see what your monthly payments could look like?";
                            calculatorTitle.style.cssText = "font-weight:bold;font-size:1.30rem;margin-bottom:12px";
                            calculatorTitle.style.color = colors.primaryTextColor || "#000000";
                            return calculatorTitle;
                        })(document.createElement("div"))
                    );
                    containerDiv.appendChild(
                        (function (amountTextDiv) {
                            amountTextDiv.innerHTML = "Loan Amount";
                            amountTextDiv.style.cssText = "font-size:0.9rem;font-weight:normal";
                            amountTextDiv.style.color = colors.primaryTextColor || "#000000";
                            return amountTextDiv;
                        })(document.createElement("div"))
                    );
                    containerDiv.appendChild(
                        (function (amountDiv) {
                            amountDiv.id = "lg-calculator-amount";
                            amountDiv.className = "lg-calculator-amount";
                            amountDiv.innerHTML = utils.prettifyCurrency(12000, true);
                            amountDiv.style.cssText = "font-size:0.9rem;font-weight:bold";
                            amountDiv.style.color = colors.primaryTextColor || "#000000";
                            return amountDiv;
                        })(document.createElement("div"))
                    );
                    containerDiv.appendChild(
                        (function (slider) {
                            slider.className = "lg-calculator-slider";
                            slider.style.cssText = "-webkit-appearance:none;width:99%;height:2px;background:black;border-radius:2px;margin-top:16px";
                            slider.setAttribute("min", getPaymentsResponse.data.MinimumCalculatorAmount);
                            slider.setAttribute("max", getPaymentsResponse.data.MaximumCalculatorAmount);
                            slider.setAttribute("value", 12000);
                            slider.type = "range";
                            slider.step = 50;
                            slider.oninput = utils.debounce(function (e) {
                                document.getElementById("lg-calculator-amount").innerHTML = utils.prettifyCurrency(this.value, true);

                                let loanPlans = new XMLHttpRequest();
                                loanPlans.onreadystatechange = function () {
                                    if (loanPlans.readyState == XMLHttpRequest.DONE && loanPlans.status === 200) {
                                        addTableHeader(
                                            document.getElementById("plans-container"),
                                            JSON.parse(loanPlans.response).data.PaymentType2Available
                                        );
                                        addLoanPlanCards(
                                            document.getElementById("plans-container"),
                                            JSON.parse(loanPlans.response).data.LoanPlans,
                                            JSON.parse(loanPlans.response).data.PaymentType2Available
                                        );
                                    }
                                };
                                loanPlans.open("POST", `${apiURL}/api/v1/payments`, true);
                                loanPlans.setRequestHeader("content-type", "application/json");
                                loanPlans.send(
                                    JSON.stringify({
                                        businessId,
                                        serviceProviderId,
                                        loanPurpose,
                                        amount: this.value,
                                    })
                                );
                            }, 320);
                            return slider;
                        })(document.createElement("input"))
                    );
                    containerDiv.appendChild(
                        (function (minMaxAmountDiv) {
                            minMaxAmountDiv.style.cssText =
                                "display:flex;align-items:center;justify-content:space-between;font-size:0.7rem;margin-top:4px";
                            minMaxAmountDiv.appendChild(
                                (function (min) {
                                    min.innerHTML = utils.prettifyCurrency(getPaymentsResponse.data.MinimumCalculatorAmount, true);
                                    return min;
                                })(document.createElement("div"))
                            );
                            minMaxAmountDiv.appendChild(
                                (function (max) {
                                    max.innerHTML = utils.prettifyCurrency(getPaymentsResponse.data.MaximumCalculatorAmount, true);
                                    return max;
                                })(document.createElement("div"))
                            );
                            return minMaxAmountDiv;
                        })(document.createElement("div"))
                    );

                    addTableHeader(containerDiv, getPaymentsResponse.data.PaymentType2Available);
                    containerDiv.appendChild(
                        (function (plansContainer) {
                            plansContainer.id = "plans-container";
                            addLoanPlanCards(plansContainer, getPaymentsResponse.data.LoanPlans, getPaymentsResponse.data.PaymentType2Available);
                            return plansContainer;
                        })(document.createElement("div"))
                    );
                    containerDiv.appendChild(
                        (function (ratesApprovalDiv) {
                            ratesApprovalDiv.style.cssText = "font-size:0.7rem";
                            ratesApprovalDiv.innerHTML = "These are estimated monthly payments and are subjects to credit approval.";
                            return ratesApprovalDiv;
                        })(document.createElement("div"))
                    );
                } else if (getPayments.readyState == XMLHttpRequest.DONE && getPayments.status !== 200) {
                    document.getElementById("lg-calculator-loading") && document.getElementById("lg-calculator-loading").remove();
                    containerDiv.appendChild(
                        (function (error) {
                            error.id = "lg-calculator-error";
                            error.style.cssText = "text-align:center;font-size:0.8rem;color:#FF0000";
                            error.innerHTML = "Some error occurred!";
                            return error;
                        })(document.createElement("div"))
                    );
                }
            };
            getPayments.open("POST", `${apiURL}/api/v1/payments`, true);
            getPayments.setRequestHeader("content-type", "application/json");
            getPayments.send(
                JSON.stringify({
                    businessId,
                    serviceProviderId,
                    loanPurpose,
                    amount: 12000,
                })
            );
        });
    }
    /** calculator-element */

    let ui = {
        refresh: function () {
            initLearnMoreElement();
            initHeaderElement();
            initFinancingElement();
            initVideoElement();
            initFaqsElement();
            initPayments();
        },
    };
    window.loanglide.ui = ui;

    let checkout = {
        open: function (data) {
            const businessId = window.loanglide.BusinessId;

            let serviceProviderId = window.loanglide.ServiceProviderId;
            if (data.serviceProviderId) {
                serviceProviderId = data.serviceProviderId;
            }
            let dataVersion;
            if (data.version) {
                dataVersion = data.version;
            }

            if (data && Object.keys(data).length && businessId) {
                const spinnerUrl = "https://cdn.loanglide.com/assets/images/loading.svg";
                document.body.style.cssText = "overflow:hidden;";
                document.body.appendChild(
                    (function (containerDiv) {
                        containerDiv.id = "lg-app";
                        containerDiv.className = "lg-app";
                        containerDiv.style.cssText =
                            "-webkit-tap-highlight-color:transparent;overflow:hidden;position:fixed;bottom:0px;left:0px;right:0px;top:0px;z-index:9999999;margin:0px;border:0px;padding:0px;display:block;";
                        containerDiv.appendChild(
                            (function (iframeContainer) {
                                iframeContainer.id = "iframe-container";
                                iframeContainer.style.cssText = `width:100%;height:100%;margin:0px;padding:0px;background:url(${spinnerUrl})centerno-repeat;background-size:48px48px;`;
                                iframeContainer.appendChild(
                                    (function (iframeEl) {
                                        iframeEl.id = "lg-app-checkout";
                                        iframeEl.title = "Loanglide Borrower App";
                                        iframeEl.sandbox = "allow-forms allow-modals allow-popups allow-same-origin allow-scripts";
                                        iframeEl.allowTransparency = true;
                                        iframeEl.allowScroll = "no";
                                        iframeEl.height = "100%";
                                        iframeEl.width = "100%";
                                        iframeEl.style.cssText =
                                            "position:fixed;overflow:hidden;width:100%;height:100%;margin:0px;border:none;padding:0px;";

                                        // let borrowerAppURL = "http://localhost:3001";
                                        let borrowerAppURL = `${process.env.BORROWER_APP_URL}`;
                                        if (appVersion && appVersion === "v2") {
                                            borrowerAppURL = `${process.env.NEW_BORROWER_APP_URL}`;
                                        }
                                        if (dataVersion && dataVersion === "v2") {
                                            borrowerAppURL = `${process.env.NEW_BORROWER_APP_URL}`;
                                        }
                                        iframeEl.src = `${borrowerAppURL}/application/checkout-review?Business=${businessId}&ServiceProvider=${serviceProviderId}`;
                                        return iframeEl;
                                    })(document.createElement("iframe"))
                                );
                                return iframeContainer;
                            })(document.createElement("div"))
                        );
                        return containerDiv;
                    })(document.createElement("div"))
                );

                let checkoutFrame = document.getElementById("lg-app-checkout");
                let checkoutData = {};
                checkoutData = data;
                // console.info("data-sending-to-lg-app", checkoutData);
                sessionStorage.setItem("checkout-data", JSON.stringify(checkoutData));

                checkoutFrame.addEventListener("load", function (ev) {
                    document.getElementById("iframe-container").style.background = "";
                    if (ev.target.getAttribute("id") === "lg-app-checkout") {
                        checkoutFrame.contentWindow.postMessage(checkoutData, "*");
                    }
                });
            } else {
                throw new Error("Invalid checkout object!!");
            }
        },
        close: function (data) {
            closeIframe();
            _fireEvent(LgEvents.OnWidgetClose, data);
            _dispatchEvent(Events.OnWidgetCloseEvent, data);
        },
    };

    let events;
    (events = function () {}).prototype = {
        on: function (type, method, scope, context) {
            let listeners, handlers;
            if (!(listeners = this.listeners)) {
                listeners = this.listeners = {};
            }
            if (!(handlers = listeners[type])) {
                handlers = listeners[type] = [];
            }
            scope = scope ? scope : window;
            handlers.push({
                method: method,
                scope: scope,
                context: context ? context : scope,
            });
        },
        fireEvent: function (type, data, context) {
            let listeners, handlers, i, n, handler;
            if (!(listeners = this.listeners)) {
                return;
            }
            if (!(handlers = listeners[type])) {
                return;
            }
            for (i = 0, n = handlers.length; i < n; i++) {
                handler = handlers[i];
                if (typeof context !== "undefined" && context !== handler.context) continue;
                if (handler.method.call(handler.scope, data) === false) {
                    return false;
                }
            }
            return true;
        },
    };

    document.onreadystatechange = function () {
        window.loanglide.ui.refresh();

        if (document.readyState === "complete") {
            if (public_api_key) {
                let xml_public_key = new XMLHttpRequest();
                xml_public_key.onreadystatechange = function () {
                    if (xml_public_key.readyState == XMLHttpRequest.DONE) {
                        if (xml_public_key.status !== 200) {
                            if (xml_public_key.status === 500) {
                                console.error("Some error while initialising script!");
                            }
                        } else {
                            let response = JSON.parse(xml_public_key.response);
                            if (response.error) {
                                console.error(response.message);
                                return;
                            }

                            window.loanglide.checkout = checkout;
                            window.loanglide.events = new events();
                            window.loanglide.BusinessId = response.data.BusinessId;
                            window.loanglide.ServiceProviderId = response.data.ServiceProviderId;

                            let i, n;
                            if (window.loanglide.ready && window.loanglide.ready.onready && window.loanglide.ready.onready.length !== 0) {
                                for (i = 0, n = window.loanglide.ready.onready.length; i < n; i++) {
                                    let handler = window.loanglide.ready.onready[i];
                                    handler.method.call();
                                }
                            }
                        }
                    }
                };
                xml_public_key.open("GET", `${apiURL}/api/v1/apikeys/${public_api_key}/businesses`, true);
                xml_public_key.send(null);
            }
        }
    };

    let eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    let postEventToHost = window[eventMethod];
    let messageEvent = eventMethod === "attachEvent" ? "onmessage" : "message";

    const AppEvents = {
        AppLoaded: "app-loaded",
        AppClosed: "app-closed",
    };

    const LgEvents = {
        LoanInitiated: "loan-initiated",
        LoanSubmitted: "loan-submitted",
        LoanPreApproved: "loan-preapproved",
        LoanRejected: "loan-rejected",
        LoanApproved: "loan-approved",
        OnWidgetClose: "on-widget-close",
        ESignFailed: "esign-failed",
        NoOffersFound: "no-offers-found",
        CloseWidget: "close-widget",
        LoanInVOIE: "loan-in-voie",
    };
    // LoanInVOIE -> status(under-review), type(full, verbal)
    const Events = {
        LoanInitiatedEvent: new Event(LgEvents.LoanInitiated),
        LoanSubmittedEvent: new Event(LgEvents.LoanSubmitted),
        LoanPreApprovedEvent: new Event(LgEvents.LoanPreApproved),
        LoanRejectedEvent: new Event(LgEvents.LoanRejected),
        LoanApprovedEvent: new Event(LgEvents.LoanApproved),
        OnWidgetCloseEvent: new Event(LgEvents.OnWidgetClose),
        ESignFailedEvent: new Event(LgEvents.ESignFailed),
        NoOffersFoundEvent: new Event(LgEvents.NoOffersFound),
        LoanInVOIEEvent: new Event(LgEvents.LoanInVOIE),

        AppLoadedEvent: new Event(AppEvents.AppLoaded),
        AppLoadedClosed: new Event(AppEvents.AppLoaded),
    };

    function closeIframe() {
        let loanglideApp = document.getElementById("lg-app");
        if (loanglideApp) {
            loanglideApp.parentNode.removeChild(loanglideApp);
            document.body.style.removeProperty("overflow");
        }
    }
    function _fireEvent(event, data) {
        !!window.loanglide.events &&
            !!window.loanglide.events.listeners &&
            Object.keys(window.loanglide.events.listeners).length !== 0 &&
            !!window.loanglide.events.listeners[event] &&
            window.loanglide.events.fireEvent(event, data);
    }
    function _dispatchEvent(event, data) {
        event.response = data;
        window.dispatchEvent(event);
    }

    function _handleAppEvents(event) {
        switch (event) {
            case AppEvents.AppLoaded:
                const checkoutFrame = document.getElementById("lg-app-checkout");
                const checkoutData = sessionStorage.getItem("checkout-data");
                checkoutFrame.contentWindow.postMessage(JSON.parse(checkoutData), "*");
                sessionStorage.removeItem("checkout-data");
                break;
        }
    }

    postEventToHost(messageEvent, function (e) {
        const event = e.data.event;
        const data = e.data.data;

        switch (event) {
            case AppEvents.AppLoaded:
                _handleAppEvents(event);
                break;
            case AppEvents.AppClosed:
                _handleAppEvents(event);
                break;
            case LgEvents.CloseWidget:
                closeIframe();
                break;
            case LgEvents.OnWidgetClose:
                closeIframe();
                _fireEvent(LgEvents.OnWidgetClose, data);
                _dispatchEvent(Events.OnWidgetCloseEvent, data);
                break;
            case LgEvents.LoanInitiated:
                _fireEvent(LgEvents.LoanInitiated, data);
                _dispatchEvent(Events.LoanInitiatedEvent, data);
                break;
            case LgEvents.LoanSubmitted:
                _fireEvent(LgEvents.LoanSubmitted, data);
                _dispatchEvent(Events.LoanSubmittedEvent, data);
                break;
            case LgEvents.LoanPreApproved:
                _fireEvent(LgEvents.LoanPreApproved, data);
                _dispatchEvent(Events.LoanPreApprovedEvent, data);
                break;
            case LgEvents.LoanRejected:
                // closeIframe();
                _fireEvent(LgEvents.LoanRejected, data);
                _dispatchEvent(Events.LoanRejectedEvent, data);
                break;
            case LgEvents.LoanApproved:
                // closeIframe();
                _fireEvent(LgEvents.LoanApproved, data);
                _dispatchEvent(Events.LoanApprovedEvent, data);
                break;
            case LgEvents.ESignFailed:
                _fireEvent(LgEvents.ESignFailed, data);
                _dispatchEvent(Events.ESignFailedEvent, data);
                break;
            case LgEvents.NoOffersFound:
                _fireEvent(LgEvents.NoOffersFound, data);
                _dispatchEvent(Events.NoOffersFoundEvent, data);
                break;
            case LgEvents.LoanInVOIE:
                _fireEvent(LgEvents.LoanInVOIE, data);
                _dispatchEvent(Events.LoanInVOIEEvent, data);
                break;
            default:
                break;
        }
    });
})(window, document);
